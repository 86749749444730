@import 'bootstrap-custom-variables';
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap-legacy';
@import 'ngx-toastr/toastr-bs5-alert';

html,
body {
    min-height: 100vh;
    height: auto;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.flex-even {
    flex: 1;
}

.test {
    border: solid 1px red;
}

.test1 {
    border: solid 1px green;
}

.test2 {
    border: solid 1px blue;
}

.test3 {
    border: solid 1px yellow;
}

.feedback-icon {
    position: absolute;
    width: auto;
    bottom: 10px;
    right: 10px;
    margin-top: 0;
}

/* Toastr modifications */
.toast-container {
    .toast {
        width: 450px;
    }

    margin-bottom: 60px;
}

.modal-body {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    margin-bottom: 0;
}

.modal-full {
    margin-left: 0;
    margin-right: 0;
    max-width: none;
    height: calc(100% - 3.5rem); // BS ._modal.scss specifies 1.75rem margin for top and bottom

    @include media-breakpoint-down(lg) {
        height: 100%;
    }
}

.modal-half {
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
    height: calc(100% - 3.5rem); // BS ._modal.scss specifies 1.75rem margin for top and bottom

    @include media-breakpoint-down(lg) {
        height: 100%;
    }
}

.modal-dialog {
    padding: 0 40px;

    @include media-breakpoint-down(lg) {
        padding: 15px;
        margin: 0;
        max-width: 100%;
    }
}

.close {
    padding: 10px 1rem 0 0 !important;
}

.modal-content {
    .modal-header {
        color: $mineshaft;
        padding: 1rem 0 0.5rem 0;
    }
}

.modal-footer {
    border-top: none;
}

.main {
    background-repeat: no-repeat;
    background-position: bottom -300px left -150px;
}

label {
    margin-bottom: 0;
}

label.error {
    color: $danger;
}

h1 {
    font-size: 1.4em;
    text-transform: uppercase;
}

h2 {
    font-size: 1.3em;
    text-transform: uppercase;
}

h3 {
    font-size: 1.2em;
    text-transform: uppercase;
}

h4 {
    font-size: 1.1em;
    text-transform: uppercase;
}

h5 {
    text-transform: uppercase;
    font-size: 1em;
}

.title-border-bottom.mb-2,
.title-border-bottom2.mb-2 {
    //default bootstrap overide
    margin-bottom: 20px !important;
}

.collapse-icon,
.c-pointer {
    cursor: pointer;
}

.disabled {
    cursor: not-allowed !important;
}

.hidden {
    visibility: hidden;
    display: none;
}

.fullHeight {
    display: flex;
    align-items: center;
    height: 33.61px;
}

.bold {
    font-weight: bold;
}

hr {
    height: 2px !important;
    border: none !important;
}

.collapse-label {
    vertical-align: super;
    padding-right: 10px;
    cursor: pointer;
}

.process-nav {
    .arrow {
        width: 0;
        height: 0;
        position: relative;
        border-top: 40px solid transparent;
        border-bottom: 40px solid transparent;
        top: -5px;
    }

    .arrow:after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        border-top: 31px solid transparent;
        border-bottom: 31px solid transparent;
        right: 3px;
        top: -31px;
    }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
}

.btn-primary,
.btn-secondary,
.btn-success,
.btn-danger,
.btn-warning,
.btn-info,
.btn-dark,
.btn-default,
.btn-proceed,
.btn-glassfit {
    color: white !important;
}

.btn-proceed {
    background-color: $warning !important;
    border: none !important;

    &:hover {
        background-color: lighten($warning, 15%) !important;
    }

    &:disabled {
        background-color: rgba(0, 0, 0, 0.1) !important;
    }
}

.btn-glassfit {
    background-color: $pomegranade !important;
    border: none !important;

    &:hover {
        background-color: lighten($pomegranade, 15%) !important;
    }

    &:disabled {
        background-color: rgba(0, 0, 0, 0.1) !important;
    }
}

#nav {
    z-index: 9 !important;
    position: fixed !important;
    left: 0 !important;
    right: 0 !important;
    height: 100px !important;
    color: $seashell;

    h1,
    h2 {
        margin-bottom: 0;
        text-shadow: 1px 1px 3px #000;
        padding-right: 0.2em;
    }

    .logo-visavy {
        display: flex;
        width: 120px;
        height: 100%;
        align-items: center;

        img {
            width: 100%;
        }
    }
}

nav {
    padding: 0;

    button {
        border: 0 !important;
    }

    button:focus {
        outline: none !important;
        box-shadow: none !important;
    }

    .navbar-toggler > .close {
        display: inline !important;
    }

    .navbar-toggler.collapsed > .close,
    .navbar-toggler:not(.collapsed) > .navbar-toggler-icon {
        display: none !important;
        padding: 0 !important;
    }

    .nav-item {
        padding-left: 5px;
        padding-right: 5px;
    }

    .shoppingBasket {
        .faButton {
            background-color: white;
            border-radius: $border-radius;
            color: $bondi-blue;
            padding: 2px 4px 2px 2px;
        }
    }
}

.dropdown-menu {
    overflow: hidden;
}

.dropdown-item {
    background: #fff !important;
    color: #000;

    &:hover {
        background-color: $alto-grey !important;
    }

    &.active {
        color: $bondi-blue !important;
    }
}

@include media-breakpoint-down(md) {
    .navbar-collapse.collapse.show {
        z-index: $zindex-fixed;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

        @include media-breakpoint-up(sm) {
            left: auto;
            right: 15%;
            top: 30px;
            width: 250px;
            border-radius: $border-radius;
        }
    }
}

.process-nav {
    .process-nav-2 {
        fitting-header-item:last-of-type .divider-end {
            display: none !important;
        }
    }
}

.form-check-input:checked[type='radio'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='33333'/%3e%3c/svg%3e") !important;
}

fitting-refraction mpc-select .form-control,
measurement-review-dialog mpc-select .form-control {
    height: 25px;
    padding-top: 0;
    padding-bottom: 0;
}

fitting-refraction mpc-select .input-group,
measurement-review-dialog mpc-select .input-group {
    max-width: 113px !important;
}

fitting-refraction mpc-input-number .input-group {
    max-height: 33px !important;
}

fitting-fitlens mpc-select .form-control {
    height: 25px;
    padding-top: 0;
    padding-bottom: 0;
}

/*
.measurement-image {
    max-width: 80% !important;
    height: 100%;
}
*/

.manual-measurement-badge {
    position: absolute;
    top: 0;
    left: 0;
}

.manual-measurement-badge-relative {
    position: absolute;
    top: 0;
    margin-left: -10px;
}

.lensinformation {
    height: 30px;
}

.col-form-label {
    min-height: 25px;
}

.form-control-plaintext {
    padding: 0 !important;
}

.form-control-static {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}

/* Styling for medium devices and lower (with hamburger menu) */
@include media-breakpoint-down(md) {
    nav {
        .navbar-collapse {
            background-color: white !important;
            box-shadow: 0 0 10px $rainy-grey;
            /*border-radius: 5px !important;*/
            //margin-top: -30px !important; //Shift the menu up to cover the hamburger div*/
        }

        .navbar-toggler {
            padding: 0;
        }

        .navbar-toggler:not(.collapsed) {
            color: white !important;
            padding: 0 !important;
            z-index: 50;
            right: 0;
        }

        .nav-link,
        .dropdown-item,
        a {
            color: $mineshaft !important;
            white-space: nowrap !important;
        }

        /*Styling of the expanded hamburger menu */
        ul#menuItems {
            white-space: nowrap;

            li.nav-item {
                text-align: left;

                &:not(.collapsed) {
                    border-bottom: 1px solid $alto !important;
                }
            }

            li:hover {
                background-color: $alto-grey !important;
            }

            li:last-child.nav-item {
                border-bottom: 0 !important;
            }
        }
    }
}

.divider {
    display: inline;
    width: 1px;
    border-right: solid 1px $white;
    margin-left: 10px;
    margin-right: 10px;
}

.loadingoverlay {
    color: $mineshaft;
    background-color: rgba(0, 0, 0, 0.7);

    .loading {
        background-color: $alto-grey;
    }
}

.new,
.pending {
    &.bg {
        background-color: $congress-blue !important;
    }

    &.fg {
        color: $congress-blue !important;
    }
}

.servicerequestbtn {
    cursor: pointer;
    border-left: 8px solid transparent;
    border-radius: 0.25rem;
    padding: 10px 0;

    &.AwaitingProduct {
        border-color: $pomegranade;
        padding-left: 40px;

        &.on {
            background-color: $pomegranade !important;
            color: #fff;
        }
    }

    &.Received {
        border-color: $next-color;
        padding-left: 40px;

        &.on {
            background-color: $next-color !important;
        }
    }

    &.QaHandling {
        border-color: $procornea-green;
        padding-left: 40px;

        &.on {
            background-color: $procornea-green !important;
        }
    }

    &.PsHandling {
        border-color: $congress-blue;
        padding-left: 40px;

        &.on {
            background-color: $congress-blue !important;
            color: #fff;
        }
    }

    &.Handled {
        border-color: $good;
        padding-left: 40px;

        &.on {
            background-color: $good !important;
        }
    }

    &.credits {
        border-color: $pomegranade;
        padding-left: 40px;

        &.on {
            background-color: $pomegranade !important;
        }
    }

    &.credits_completed {
        border-color: $good;
        padding-left: 40px;

        &.on {
            background-color: $good !important;
        }
    }

    span {
        display: inline-block;
        width: 40px;
        text-align: center;
    }
}

.waitingforresponse,
.waiting,
.waitingproduct,
.awaitingproduct {
    &.bg {
        background-color: $pomegranade !important;
    }

    &.fg {
        color: $pomegranade !important;
    }
}

.qahandling {
    &.bg {
        background-color: $congress-blue !important;
    }

    &.fg {
        color: $congress-blue !important;
    }
}

.pshandling {
    &.bg {
        background-color: $procornea-green !important;
    }

    &.fg {
        color: $procornea-green !important;
    }
}

.received {
    &.bg {
        background-color: $next-color !important;
    }

    &.fg {
        color: $next-color !important;
    }
}

.invoiced {
    &.bg {
        background-color: $purple !important;
    }

    &.fg {
        color: $purple !important;
    }
}

.evaluation {
    &.bg {
        background-color: $congress-blue !important;
    }

    &.fg {
        color: $congress-blue !important;
    }
}

.feedback {
    &.bg {
        background-color: $bondi-blue !important;
    }

    &.fg {
        color: $bondi-blue !important;
    }
}

.ordered,
.production {
    &.bg {
        background-color: lightskyblue !important;
    }

    &.fg {
        color: lightskyblue !important;
    }
}

.shipped {
    &.bg {
        background-color: $success !important;
    }

    &.fg {
        color: $success !important;
    }
}

.no-filter {
    &.bg {
        background-color: $procornea-green !important;
    }

    &.fg {
        color: $procornea-green !important;
    }
}

.receipt-ordered {
    &.bg {
        background-color: #1e81b0 !important;
    }

    &.fg {
        color: #1e81b0 !important;
    }
}

.receipt-notordered {
    &.bg {
        background-color: #abdbe3 !important;
    }

    &.fg {
        color: #abdbe3 !important;
    }
}

.new,
.completed,
.handled {
    &.bg {
        background-color: $success !important;
    }

    &.fg {
        color: $success !important;
    }
}

.cancelled {
    &.bg {
        background-color: $silver !important;
    }

    &.fg {
        color: $silver !important;
    }
}

.credited {
    &.bg {
        background-color: $warning !important;
    }

    &.fg {
        color: $warning !important;
    }
}

.orderstatusitem,
.serviceitem {
    &.new .statusbar,
    &.pending .statusbar,
    &.qahandling .statusbar {
        background-color: $congress-blue;
        border-left-color: $congress-blue;
    }

    &.pshandling .statusbar {
        background-color: $procornea-green;
        border-left-color: $procornea-green;
    }

    &.received .statusbar {
        background-color: $next-color;
        border-left-color: $next-color;
    }

    &.waitingforresponse .statusbar,
    &.waiting .statusbar,
    &.waitingproduct .statusbar,
    &.awaitingproduct .statusbar {
        background-color: $pomegranade;
        border-left-color: $pomegranade;
    }

    &.evaluation .statusbar {
        background-color: $purple;
        border-left-color: $purple;
    }

    &.evaluation .statusbar {
        background-color: $congress-blue;
        border-left-color: $congress-blue;
    }

    &.ordered .statusbar,
    &.production .statusbar {
        background-color: lightskyblue;
        border-left-color: lightskyblue;
    }

    &.feedback .statusbar {
        background-color: $bondi-blue;
        border-left-color: $bondi-blue;
    }

    &.shipped .statusbar {
        background-color: $success;
        border-left-color: $success;
    }

    &.invoiced .statusbar {
        background-color: $purple;
        border-left-color: $purple;
    }

    &.receipt-notordered .statusbar {
        background-color: #abdbe3;
        border-left-color: #abdbe3;
    }

    &.receipt-ordered .statusbar {
        background-color: #1e81b0;
        border-left-color: #1e81b0;
    }

    &.completed .statusbar,
    &.handled .statusbar {
        background-color: $success;
        border-left-color: $success;
    }

    &.cancelled .statusbar {
        background-color: $silver;
        border-left-color: $silver;
    }

    &.credited .statusbar {
        background-color: $warning;
        border-left-color: $warning;
    }

    &.no-filter .statusbar {
        background-color: $procornea-green;
        border-left-color: $procornea-green;
    }
}

tabset {
    .nav-item:not(.active) {
        &.hidewheninactive {
            display: none;
        }
    }
}

div.screensaver {
    max-width: 1200px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .modal-content {
        margin-top: auto;
        margin-bottom: auto;
    }
}

div.releaseNotesDialog {
    max-width: 800px;
}

.carousel-indicators {
    bottom: -40px;

    li {
        width: 10px;
        height: 10px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 50%;
    }

    li.active {
        background-color: white;
    }
}

.post-content {
    figure {
        img {
            width: 100% !important;
            height: auto;
        }
    }
}

@include media-breakpoint-down(sm) {
    div.settingsDialog {
        padding: 1em;
        margin: 0 !important;
        height: 100%;
        min-width: 400px !important;
    }

    .modal-content {
        height: 100%;
    }

    .screensaver {
        .modal-content {
            height: auto !important;
        }
    }

    .modal-body {
        overflow: hidden;
        max-height: 100% !important;
    }
}

@include media-breakpoint-up(sm) {
    div.settingsDialog {
        min-width: 400px !important;
    }
}

@include media-breakpoint-up(md) {
    div.settingsDialog {
        min-width: 600px !important;
    }
}

@include media-breakpoint-up(lg) {
    div.settingsDialog {
        min-width: 900px !important;
    }
}

@include media-breakpoint-up(sm) {
    div.licensedialog {
        min-width: 550px !important;
    }
}

@include media-breakpoint-up(md) {
    div.licensedialog {
        min-width: 750px !important;
    }
}

@include media-breakpoint-up(lg) {
    div.licensedialog {
        min-width: 1150px !important;
    }
}

.pulsating {
    animation: pulsating 1s infinite;
}

@keyframes pulsating {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.75);
    }

    100% {
        transform: scale(1);
    }
}

.legend {
    &.position {
        width: 130px;
    }

    &.angle {
        width: 100px;
    }

    &.colon {
        width: 20px;
    }

    &.radiusandsphere {
        width: 90px;
    }

    &.evalue {
        width: 100px;
    }

    &.thickness {
        width: 110px;
    }
}

table.datatable {
    color: #f8f8f8;
}

.noimage {
    color: $silver;
}

.datatable {
    border-collapse: separate;
    border-spacing: 0 5px;
}

.datatable td {
    border: 0;
    vertical-align: middle;
}

.datatable tbody tr {
    line-height: 0.9em;

    &:hover {
        cursor: pointer;
    }

    &:not(.shipped) {
        cursor: default;
    }
}

.datatable thead th {
    border: 0;
    padding-top: 5px;
    padding-bottom: 0;
}

.table-fixed {
    table-layout: fixed;
}

.td-w-10 {
    width: 10%;
}

.td-w-20 {
    width: 20%;
}

.td-w-30 {
    width: 30%;
}

.orderlineicon {
    cursor: zoom-in;
    height: 20px;
    width: 20px;
}

a.returnIcon {
    img {
        width: 20px;
        height: 20px;
    }
}

.shoppingcartcount {
    top: -12px;
    right: -12px;
}

.visavyCE {
    background-size: 300px;
    width: 300px;
    height: 110px;
}

.home-nav {
    #clients,
    #orderStatus {
        .icon {
            position: relative;
        }

        .replacementCount {
            top: -10px;
            right: -10px;
        }

        .supportOrderCount {
            top: -10px;
            right: -25px;
        }
    }
}

.procornea-green {
    background-color: $procornea-green;
    color: white;

    &:hover {
        background-color: darken($procornea-green, 5%) !important;
        color: white;
    }
}

.next {
    background-color: $next-color;
    color: white;

    &:hover {
        background-color: darken($next-color, 5%) !important;
        color: white;
    }
}

.good {
    background-color: $good;
    color: white;

    &:hover {
        background-color: darken($good, 5%) !important;
        color: white;
    }
}

.bad {
    background-color: $bad;
    color: white;

    &:hover {
        background-color: darken($bad, 5%) !important;
        color: white;
    }
}

.tooltip {
    background-color: $tooltip;
    color: white;

    &:hover {
        background-color: darken($tooltip, 5%) !important;
        color: white;
    }
}

.carousel .carousel-control {
    visibility: hidden;
}

.pagination {
    button {
        background: none;
        border: 1px solid transparent;
        padding: 3px 8px;

        &:not(:last-child) {
            margin-right: -1px;
        }

        &:disabled {
            opacity: 0.5;
        }

        &:first-child {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }

        &:last-child {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }

        &:focus {
            outline: none;
        }
    }
}

.cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1.2em;
    white-space: nowrap;
}

.link {
    &:hover {
        text-decoration: underline;
    }
}

.btn-reset {
    border: none;
    padding: 0;
    background: none;
}

.no-caret {
    caret-color: transparent;
}

.transparent-bg {
    background-color: transparent;

    th,
    tr,
    td {
        background-color: transparent;
    }
}

select {
    &:disabled {
        opacity: 0.5;
    }
}

.hide-when-disabled {
    &:disabled {
        display: none;
    }
}

.driver-popover-footer {
    background: #fff;
}
