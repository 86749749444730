.form-group {
    margin-bottom: 0.3em;
}

.table {
    --bs-table-bg: transparent;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block + .btn-block {
    margin-top: 0.5rem;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
}

.table > :not(caption) > * > * {
    padding: 0.75rem;
    color: inherit;
}

.form-check-input[type='checkbox'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: $secondary;

    color: $secondary;
    width: 1em;
    height: 1em;
    // border: 0.15em solid $secondary;
    border-radius: 5px;
    // transform: translateY(-0.075em);

    display: grid;
    place-content: center;
}

.form-check-input[type='checkbox']::before {
    content: '';
    width: 1em;
    height: 1em;
    transform: scale(0);
    clip-path: polygon(28% 38%, 41% 53%, 75% 24%, 86% 38%, 40% 78%, 15% 50%);
    transition: 120ms transform ease-in-out;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

.form-check-input[type='checkbox']:checked {
    background-color: $secondary;
    border-color: $secondary;
}

.form-check-input[type='checkbox']:checked::before {
    transform: scale(1);
}

.form-check-input[type='checkbox']:disabled {
    --form-control-color: $secondary;

    color: var(--form-control-disabled);
    cursor: not-allowed;
}

.form-check-input[type='radio']:checked {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23000'/%3e%3c/svg%3e") !important;
    background-color: $secondary;
}
